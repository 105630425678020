import React from 'react';
import './Avatar.css'; // Assurez-vous d'importer le fichier CSS

function Avatar() {
  return (
    <div id="avatar" className="avatar-container">
      <img
        src="https://avatars.githubusercontent.com/u/45120729?v=4"
        alt="Avatar de Mickael Lherminez"
        className="avatar-image"
      />
    </div>
  );
}

export default Avatar;
