export function createIcons() {
  const container = document.getElementById('space-container');
  if (!container) return;

  container.innerHTML = ''; // Nettoyer le conteneur avant d'ajouter de nouvelles icônes

  const icons = [
    'fa-flask',
    'fa-atom',
    'fa-brain',
    'fa-dna',
    'fa-microscope',
    'fa-rocket',
    'fa-bolt',
    'fa-code',
    'fa-microchip',
    'fa-database',
    'fa-globe',
    'fa-server',
  ];

  icons.forEach(icon => {
    const element = document.createElement('i');
    element.className = `fas ${icon} floating-icon`;
    element.style.left = `${Math.random() * (window.innerWidth - 20)}px`;
    element.style.top = `${Math.random() * (window.innerHeight - 20)}px`;

    // Ajouter une animation aléatoire
    const duration = Math.random() * 5 + 3; // Durée entre 3 et 8 secondes
    element.style.animationDuration = `${duration}s`;

    container.appendChild(element);
  });

  // Ajouter l'effet de répulsion
  container.addEventListener('mousemove', e => {
    const icons = document.querySelectorAll('.floating-icon');
    icons.forEach(icon => {
      const rect = icon.getBoundingClientRect();
      const iconX = rect.left + rect.width / 2;
      const iconY = rect.top + rect.height / 2;

      const deltaX = iconX - e.clientX;
      const deltaY = iconY - e.clientY;
      const distance = Math.sqrt(deltaX * deltaX + deltaY * deltaY);

      if (distance < 100) {
        // Rayon d'influence du curseur
        const force = (100 - distance) / 100;
        const moveX = (deltaX / distance) * force * 20;
        const moveY = (deltaY / distance) * force * 20;

        icon.style.transform = `translate(${moveX}px, ${moveY}px)`;
      } else {
        icon.style.transform = 'translate(0, 0)';
      }
    });
  });
}
