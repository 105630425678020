import React from 'react';
import './Header.css'; // Assurez-vous d'importer le fichier CSS

function Header() {
  return (
    <header className="header">
      <h1 className="header-title">Site Under Construction</h1>
      <p className="header-subtitle">Our mad scientist is hard at work!</p>
    </header>
  );
}

export default Header;
