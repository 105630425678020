import React from 'react';
import Header from './components/Header';
import Main from './components/Main';
import Footer from './components/Footer';
import SpaceContainer from './components/SpaceContainer';
import './App.css'; // Assurez-vous que le chemin est correct et que le fichier existe

function App() {
  return (
    <div className="App flex flex-col min-h-screen">
      <SpaceContainer />
      <Header />
      <Main />
      <Footer />
    </div>
  );
}

export default App;
