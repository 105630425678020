import React, { useEffect } from 'react';
import { createIcons } from '../utils/icons';

function SpaceContainer() {
  useEffect(() => {
    createIcons();
    window.addEventListener('resize', createIcons);
    return () => window.removeEventListener('resize', createIcons);
  }, []);

  return <div id="space-container" className="fixed inset-0 pointer-events-none z-[-1]"></div>;
}

export default SpaceContainer;
