import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        <div className="footer-logo-copyright">
          <p className="footer-copyright">
            <span
              style={{
                fontSize: '2rem',
                display: 'inline-block',
                verticalAlign: 'text-bottom',
                lineHeight: 0.2,
              }}
            >
              &copy;
            </span>
            {new Date().getFullYear()}
            <img
              src="https://avatars.githubusercontent.com/u/45120729?v=4"
              alt="Logo de Mickael Lherminez"
              className="footer-logo"
              style={{ margin: '0 0.5rem', verticalAlign: 'middle' }}
            />
            <span className="footer-name">mickaellherminez.net</span>
          </p>
        </div>
        <div className="footer-social-icons">
          <a
            href="https://x.com/mickaelherminez"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-social-link"
            aria-label="X/Twitter"
          >
            <i className="fa-brands fa-x-twitter" title="X/Twitter"></i>
          </a>
          <a
            href="https://github.com/mickaellherminez"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-social-link"
            aria-label="GitHub"
          >
            <i className="fab fa-github" title="GitHub"></i>
          </a>
          <a
            href="https://www.linkedin.com/in/mickaellherminez/"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-social-link"
            aria-label="LinkedIn"
          >
            <i className="fab fa-linkedin" title="LinkedIn"></i>
          </a>
          <a
            href="https://stackoverflow.com/users/10165426/mickael-lherminez"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-social-link"
            aria-label="Stack Overflow"
          >
            <i className="fab fa-stack-overflow" title="Stack Overflow"></i>
          </a>
          <a
            href="https://codepen.io/mickaellherminez"
            target="_blank"
            rel="noopener noreferrer"
            className="footer-social-link"
            aria-label="CodePen"
          >
            <i className="fa-brands fa-codepen" title="CodePen"></i>
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
