import React from 'react';
import Avatar from './Avatar';
import './Main.css'; // Assurez-vous d'importer le fichier CSS

function Main() {
  return (
    <main className="main-container">
      <Avatar />
      <div className="main-title">Coming Soon!</div>
    </main>
  );
}

export default Main;
